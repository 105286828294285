import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Food I Made"
          description="Learn more about the home cook behind foodimade.com"
        />
        <h1>About Food I Made</h1>
        <p>
          Hi I'm Will! Welcome to my Food Blog! I am an engineer by day and love
          to cook at night. I mostly like cooking Burmese food, and also enjoy
          experimenting with adapting recipes to use new cooking techniques.
        </p>

        <h3>Other Food Blogs I Love:</h3>
        <p>
          I am inspired by other bloggers who use pressure cookers and slow
          cookers to recreate complex flavors with simple recipes.
          <ul>
            <li>
              <a href="https://dadcooksdinner.com">Dad Cooks Dinner</a>
            </li>
            <li>
              {' '}
              <a href="https://skinnytaste.com">Skinny Taste</a>{' '}
            </li>
            <li>
              {' '}
              <a href="https://www.365daysofcrockpot.com">
                365 days of slow cooking
              </a>{' '}
            </li>
            <li>
              {' '}
              <a href="https://www.pressurecookrecipes.com">Amy + Jacky</a>{' '}
            </li>
          </ul>
        </p>

        <h2>You can also check out some of the other projects I work on at:</h2>
        <ul>
          <li>
            <a href="https://infomofo.com">infomofo.com</a>
          </li>
          <li>
            <a href="https://blog.infomofo.com">my blog</a>
          </li>
          <li>
            <a href="https://2beerqueers.com">2beerqueers.com</a>
          </li>
          <li>
            <a href="https://beerxchange.com">beerxchange.com</a>
          </li>
          <li>
            <a href="https://kit.co/infomofo">kit.co/infomofo</a>
          </li>
        </ul>
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
